import { Component, Prop, Mixins } from 'vue-property-decorator';
import template from './ConfirmEmail.Template.vue';
import ConfirmEmailValidator from './ConfirmEmail.Validator';
import { UtilisateurApiHelper } from '../../../services/UtilisateurApiHelper';

const env = process.env.NODE_ENV;

@Component({
    ...template,
    name: 'ConfirmEmail',
})
export default class ConfirmEmail extends Mixins(ConfirmEmailValidator) {
    @Prop({ default: '' }) public readonly userId!: string;
    @Prop({ default: '' }) public readonly code!: string;

    public loading: boolean = true;
    public hasErrors: boolean = false;

    public mounted(): void {
        UtilisateurApiHelper.confirmEmail({ userId: this.userId, code: this.code })
            .then((result: boolean) => this.hasErrors = !result)
            .finally(() => this.loading = false);
    }
}
